<template>
  <div class="container">
    <div>
      <img src="../../assets/register_success.png" alt />
    </div>
    <h4>{{text}}</h4>
    <span>审核通过后，可正常使用</span>
  </div>
</template>
<script>
export default {
  data() {
    return {
      text: "提交成功！",
    };
  },
  created() {
    const type = this.$route.query.type;
    if (type) {
      this.text = "审核中！";
    }
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  img {
    width: 63px;
    height: 63px;
    margin-right: 10px;
  }
  h4 {
    font-size: 18px;
    margin: 10px;
  }
  span {
    font-size: 14px;
  }
}
</style>